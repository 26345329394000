import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status == 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

createApp(App).use(router).mount('#app');
